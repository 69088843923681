import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';

const Item = styled('li')``;

const News = ({ className }) => (
  <ul className={className} id="inthenews">
    <Item itemScope itemType="http://schema.org/NewsArticle">
      <Card
        description="Ingenico Group, the global leader in seamless payment, today announced its investment in Joinedapp, a start-up located in Palo Alto, California whose enterprise e-commerce..."
        image="/images/man-using-phone.jpg"
        link="https://www.ingenico.com/press-and-publications/press-releases/finance/2017/06/ingenico-group-invests-in-joinedapp.html"
        title="Ingenico Group invests in Joinedapp, a California-based start-up, to shape the future of conversational commerce"
      />
    </Item>
    <Item itemScope itemType="http://schema.org/NewsArticle">
      <Card
        description="Ingenico Group is about to take conversational commerce mainstream with the launch of a payment-enabled messaging bot that will transform the way we buy online...."
        image="/images/mobile2.jpg"
        link="https://blog.ingenico.com/posts/2017/09/chatbots-and-the-conversational-commerce-revolution.html"
        title="Chatbots and the Conversational Commerce Revolution"
      />
    </Item>
    <Item itemScope itemType="http://schema.org/NewsArticle">
      <Card
        description="Ingenico ePayments, la division d'Ingenico Group dédiée au commerce en ligne et mobile, remporte le trophée ID d’Or à l’occasion de la 4ème édition du Grand Prix de..."
        image="/images/prix-id-dor.jpg"
        link="https://ingenico.fr/media/communiques-de-presse/group/communiques-de-presse/all/2017/07/grand-prix-innovation.html"
        title="Ingenico ePayments wins the Golden ID of the Digital Innovation Grand Prix in the Commerce category (French)"
      />
    </Item>
  </ul>
);

News.propTypes = {
  className: PropTypes.string,
};

export default styled(News)`
  background: #f7f9fc;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  min-height: 75vh;
  padding: 6.4rem 2.4rem 3.2rem;
  width: 100%;

  ${Item} {
    margin-bottom: 3.2rem;
  }

  ${Card} {
    border: 0.4rem solid transparent;
    box-shadow: 0 6.4rem 6.4rem 0 rgba(0, 15, 25, 0.05);
    height: 43.1rem;
    margin: 0;
    padding: 2rem;
    transition: border-color 0.125s ease-out;
    width: 31rem;

    &:focus {
      border-color: rgba(0, 132, 255, 0.2);
      outline: 0;
    }

    &:hover {
      border-color: rgba(0, 132, 255, 0.4);
    }
  }

  @media screen and (min-width: 600px) {
    ${Card} {
      padding: 2.8rem;
      width: 38.4rem;
    }

    ${Item} {
      padding: 0 1%;
      margin-bottom: 4.8rem;
    }
  }

  @media screen and (min-width: 1280px) {
    ${Card} {
      width: 38.4rem;
    }
  }
`;
