import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from '@styled-components';

const Description = styled('p')``;
const Image = styled('img')``;
const Title = styled('h3')``;

const Card = ({
  className,
  description,
  image = '/images/placeholder-image.jpg',
  link,
  title,
}) => (
  <OutboundLink
    className={className}
    href={link}
    itemProp="url"
    rel="noopener"
    target="_blank"
  >
    <Image alt={title} src={image} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </OutboundLink>
);

Card.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default styled(Card)`
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0 64px 64px 0 rgba(0, 15, 25, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2.4rem;
  padding: 1.6rem;
  position: relative;

  ${Image} {
    border-radius: 0.6rem;
    max-width: 100%;
  }

  ${Title} {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 1.6rem 0 0;
  }

  ${Description} {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin: 0.8rem 0 0;
    opacity: 0.6;
    overflow: hidden;
  }

  @media screen and (min-width: 600px) {
    min-height: 35rem;

    ${Title} {
      margin-top: 3.2rem;
    }

    ${Description} {
      margin-top: 1.6rem;
    }
  }
`;
