import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { createGlobalStyle } from 'styled-components';

import ThankYou from 'Components/ThankYou';
import Links from './Links';

const GlobalStyle = createGlobalStyle`
  nav {
    display: none !important;
    & + button {
      display: none !important;
    }
  }
`;

const Greetings = ({ className }) => (
  <div className={className}>
    <GlobalStyle />
    <ThankYou />
    <Links />
  </div>
);

Greetings.propTypes = {
  className: PropTypes.string,
};

export default styled(Greetings)`
  align-items: center;
  background: #003566;
  display: flex;
  flex-direction: column;
  margin-bottom: 6.4rem;
  position: relative;
`;
