import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Slide from 'Components/Slide';

const Actions = styled('section')``;
const Link = styled('a')``;
const Card = styled('section')``;
const Description = styled('p')``;
const Image = styled('img')``;
const Title = styled('h2')``;

const ThankYou = ({ className }) => (
  <Slide className={className} in>
    <Card>
      <FormattedMessage capitalize component={Title} id="THANK_YOU.TITLE">
        Thank you
      </FormattedMessage>
      <FormattedMessage
        capitalize
        component={Description}
        id="THANK_YOU.DESCRIPTION"
      >
        For your request, we will contact you shortly.
      </FormattedMessage>
      <FormattedMessage
        capitalize
        component={Description}
        id="THANK_YOU.DESCRIPTION2"
      >
        Meanwhile fell free to browse the related blogs and research papers
        below.
      </FormattedMessage>
      <Actions>
        <FormattedMessage
          capitalize
          component={Link}
          id="ACTIONS.DOWNLOAD"
          target="blank"
          href={withPrefix('/joinedapp_Pillar-Asset_011520_R2-V1.pdf')}
        />
      </Actions>
    </Card>
    <Image src={withPrefix('/images/thank-you.png')} />
  </Slide>
);

ThankYou.propTypes = {
  className: PropTypes.string,
};

export default styled(ThankYou)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  position: relative;

  &.wrapper {
    align-items: flex-start;
    background-color: #003566;
    height: 39.6rem;
    padding: 2.4rem;
  }

  ${Card} {
    background: #ffffff;
    border-radius: 0.8rem;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 53, 102, 0.04),
      0px 2px 4px rgba(0, 53, 102, 0.08);
    min-height: 34rem;
    padding: 3.2rem 2rem;

    ${Title} {
      font-size: 3.4rem;
      font-weight: 400;
      letter-spacing: -0.15rem;
      line-height: 4rem;
      margin-bottom: 1.6rem;
    }

    ${Description} {
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: 0;
      margin: 0 0 2.4rem;
      opacity: 0.6;

      &:last-of-type {
        margin: 0;
      }
    }

    ${Actions} {
      bottom: 3.2rem;
      position: absolute;

      ${Link} {
        background-image: linear-gradient(69deg, #005dff, #0095ff);
        border-radius: 0.6rem;
        color: white;
        cursor: pointer;
        display: block;
        font-size: 1.4rem;
        height: 4.8rem;
        line-height: 1.4rem;
        padding: 1.6rem;
        text-align: center;
        text-decoration: none;
        width: 100%;
      }
    }
  }

  ${Image} {
    display: none;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    padding: 0 2.4rem;

    &.wrapper {
      align-items: center;
      height: 44.6rem;
    }

    ${Card} {
      width: 54.8rem;
      padding: 4.8rem 4rem 12rem;
      height: auto;

      ${Title} {
        font-size: 4rem;
        font-weight: 500;
        line-height: 4.8rem;
        letter-spacing: -0.15rem;
        margin-bottom: 4rem;
      }

      ${Actions} {
        bottom: 4.8rem;

        ${Link} {
          display: inline-block;
          width: auto;
        }
      }
    }
    ${Image} {
      display: block;
      margin-left: 2.4rem;
      max-width: 29rem;
    }
  }
`;
