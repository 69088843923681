import React from 'react';
import cx from 'classnames';
import PropTypes from '@prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from '@styled-components';

const Header = styled('header')``;
const Title = styled('h2')``;

const Slide = ({
  children,
  className,
  color = '#ffffff',
  id,
  in: visible,
  title,
  ...props
}) => (
  <CSSTransition appear classNames="" in={visible} timeout={0}>
    {state => (
      <section
        className={cx(className, state, 'wrapper')}
        data-color={color}
        data-slide
        id={id}
        {...props}
      >
        {title && (
          <Header>
            <Title>{title}</Title>
          </Header>
        )}
        <article className={cx(className, state)}>{children}</article>
      </section>
    )}
  </CSSTransition>
);

Slide.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
  in: PropTypes.bool,
  title: PropTypes.string,
};

export default styled(Slide)`
  background-color: ${props => props.color};
  &.wrapper {
    align-items: center;
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    margin: 0;
    margin-left: 0 !important;
    opacity: 1;
    padding: 1.6rem 2.4rem;
    position: relative;
    transition: opacity 0.25s ease-out;
    width: 100%;

    &.entered {
      opacity: 1;
    }

    &.exited {
      opacity: 0;
      transition: opacity 0.25s ease-out;
    }

    ${Header} {
      width: 100%;
      max-width: 144rem;
    }

    ${Title} {
      align-self: flex-start;
      font-size: 2.8rem;
      font-weight: 500;
      letter-spacing: -0.1rem;
      line-height: 4rem;
      margin: 0 0 5% 8%;
    }

    @media screen and (min-width: 600px) {
      min-height: 75vh;
      padding: 2.4rem 8%;

      ${Title} {
        font-size: 4rem;
        letter-spacing: -0.1rem;
        line-height: 4.8rem;
      }
    }
  }
`;
